import GLightbox from "glightbox";

document.addEventListener("DOMContentLoaded", function (event) {

    const menu_button = document.querySelector('[data-behaviour="toggle-menu"]');

    menu_button.addEventListener('click', () => {
        document.body.classList.toggle('body--show');
    })

    const lightbox = GLightbox({
        selector: '[data-gallery="gallery"]',
        touchNavigation: true,
        loop: true,
    });

    const select_menus = document.querySelectorAll('[data-behaviour="select-menu"]');

    [...select_menus].forEach(select => select.addEventListener('change', function() {
        location.href=this.value;
    }))
});